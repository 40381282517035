<template>
  <div class="full_popup">
    <div class="row h-100 m-0 align-items-center justify-content-center">
      <div class="col-11 col-md-8 col-lg-6 col-xxl-6 p-0 card_item">
        <div class="row mx-0 h-100 justify-content-center">
          <div class="col-12 p-3 pb-0">
            <div class="row m-0 justify-content-center holder">
              <div class="bg_holder">
                <div class="background" >
                </div>
              </div>
              <div class="col-12 p-3 above">
                <div class="row">
                  <div class="col-10 offset-1 pt-2">
                    <div class="row justify-content-center">
                      <div class="col-auto text-center p-0 card_title">
                        GROUP {{item.donorGroupName}}
                        <div class="row m-0 justify-content-center" >
                          <div class="col-12 text-center pt-3 border_bottom">
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-1 text-end cursor" @click="$emit('close')">
                    <ios-close-icon class="inline-icon green-text-dark" />
                  </div>
                </div>
                <div class="row mx-0 my-4 justify-content-center align-items-center" >
                  <div class="col-auto ps-0 text-center">
                    <div class="profile_img">
                      <IconUser size="sizefull" />
                    </div>
                  </div>
                  <div class="col-auto p-0">
                    <div class="medium green-text font19">
                      <!-- item.founder.firstName item.founder.lastName -->
                      {{item.groupFounder.firstName}} {{item.groupFounder.lastName}}
                    </div>
                    <div class="medium green-text font10 letter_spacing">
                      GROUP FOUNDER
                    </div>
                  </div>
                </div>
                <div class="row mx-0 my-3 justify-content-center align-items-center" v-if="groupMembersStatus !== 'ready'">
                  <div class="col-auto ps-0 text-capitalize">
                    {{groupMembersStatus}} Group Members
                  </div>
                  <div class="col-auto p-0">
                    <div class="spinner-border spinner-border-sm green-text" role="status">
                      <span class="visually-hidden">Loading...</span>
                    </div>
                  </div>
                </div>
                <div class="row m-0 justify-content-center" v-else>
                  <div class="col-11 px-0 my-3 card_item_inner">
                    <div class="row mx-0 h-100 justify-content-center">
                      <div class="col-12 p-0">
                        <div class="row m-0 justify-content-center">
                          <div class="col-12 p-0 border_top" v-for="(member, key) in nonAdminMembers" :key="key">
                            <div class="row m-0 align-items-center borders py-2">
                              <!--IMAGE-->
                              <div class="col-12 text-center col-md-auto px-4">
                                <Image :imageName="member.member.imageUrl" width="40" theClass="item_img" v-if="member.member.imageUrl" />
                                <!-- <img :src="url + '/static/uploads/images/' + member.imageUrl"  class="item_img" v-if="member.member.imageUrl"> -->
                                <!-- <img src="../assets/images/Bismillah-Khutbah-1.png"  class="item_img" v-else> -->
                                <IconUser class="item_img" v-else />
                              </div>
                              <!--END IMAGE-->
                              <div class="col-12 col-md p-0 text-center text-md-start mt-2  mt-md-0">
                                <div class="row m-0">
                                  <div class="col-md-12 p-0 bold item">
                                    {{member.member.firstName}} {{member.member.lastName}}
                                  </div>
                                  <div class="col-md-12 p-0 font12">
                                    {{ member.member.email }}
                                  </div>
                                </div>
                              </div>
                              <div class="col col-md-auto text-center text-md-end bold mt-3 mt-md-0 pe-4 item">
                                <div class="row m-0">
                                  <div class="col-auto px-1">
                                    <Button color="red" size="small" btnText="Make Donation" icon="arrow" @buttonClicked="makeDonation(member)">
                                      <IconArrowForward />
                                    </Button>
                                  </div>
                                  <div class="col-auto px-1">
                                    <Button color="darkgrey_red" icon="arrow" size="xxsmall" @buttonClicked="removeMember(member)">
                                      <ios-close-icon class="font19 inline-icon" />
                                    </Button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-12 p-0 align-self-end">
            <div class="row m-0 background-green py-3 align-items-center justify-content-center">
              <div class="col-auto">
                <Button color="green_light" size="" width="100" btnText="Invite New Donor to Group" icon="arrow" @buttonClicked="addNew">
                  <IconPlus />
                </Button>
              </div>
            </div>
          </div>
        </div>
        <div class="position-absolute w-100 start-0">
          <div class="row my-4 mx-0 justify-content-center w-100">
            <div class="col-6 col-sm-5 col-md-3 col-xl-2 item_nav rounded-pill p-2 text-white bold">
              <div class="row align-items-center py-1 justify-content-between w-100 m-0">
                <div class="col-auto cursor hover" @click="goBack" v-if="theKey > 0">
                  <IconArrowBack />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowBack />
                </div>
                <div class="col p-0 text-center">
                  {{theKey + 1}}/{{totalItems}}
                </div>
                <div class="col-auto cursor hover" @click="goForward" v-if="theKey +  1 < totalItems">
                  <IconArrowForward />
                </div>
                <div class="col-auto disabled" v-else>
                  <IconArrowForward />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import { defineAsyncComponent } from 'vue'

export default {
  components: {
    Button: defineAsyncComponent(() => import('../components/Button.vue')),
    Image: defineAsyncComponent(() => import('../components/Image.vue')),
    IconArrowForward: defineAsyncComponent(() => import('../components/icons/IconArrowForward.vue')),
    IconArrowBack: defineAsyncComponent(() => import('../components/icons/IconArrowBack.vue')),
    IconUser: defineAsyncComponent(() => import('../components/icons/IconUser.vue')),
    IconPlus: defineAsyncComponent(() => import('../components/icons/IconPlus.vue')),
    'ios-close-icon': defineAsyncComponent(() => import('vue-ionicons/dist/ios-close.vue'))
  },
  name: 'Group Members Popup',
  props: ['item', 'theKey', 'totalItems'],
  async mounted () {
    await this.fetchGroupMembers(this.item.donorGroupID)
  },
  computed: {
    ...mapGetters(['groupMembers', 'groupMembersStatus']),
    nonAdminMembers () {
      return this.groupMembers.filter(groupMember => {
        if (groupMember.roleType.roleID === 8) {
          return false
        }
        return true
      })
    }
  },
  methods: {
    ...mapActions(['fetchGroupMembers', 'setDonateAs']),
    goBack () {
      this.$emit('goBack')
    },
    goForward () {
      this.$emit('goForward')
    },
    addNew () {
      this.$emit('addNew')
    },
    async makeDonation (val) {
      const donorDetails = {
        groupID: this.item.donorGroupID,
        donor: val.member
      }
      await this.setDonateAs(donorDetails)
      await this.$router.push('/checkout/2')
    },
    removeMember (val) {
      this.$emit('removeMember', val)
    }
  }
}
</script>
<style scoped>
.full_popup {
  position: fixed;
  z-index: 5;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.7);
  left: 0;
  top: 0;
}
.card_item {
  background-color: #F4F4F4F7;
  border-radius: 22px;
  box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%);
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.card_item_inner {
  background-color: rgb(249, 250, 251);
  border-radius: 15px;
  /* box-shadow: 2px 8px 23px 3px rgb(0 0 0 / 20%); */
  max-height: 80vh;
  overflow-y: auto;
  overflow-x: hidden;
}
.holder {
  position: relative;
  z-index: 2;
}
.above {
  z-index: inherit;
}
.bg_holder {
  position: absolute;
  left: -1.5rem;
  top: -1.5rem;
  z-index: 1;
  background-image: url(../assets/images/bg.png);
  background-size: 32% auto;
  border-top-left-radius: 22px;
  border-top-right-radius: 22px;
  max-width: calc(100% + 3rem);
  width: calc(100% + 3rem);
  height: calc(100% + 1.5rem);
  padding: 0;
}
.background {
  background-image: linear-gradient(180deg, #fffffff7 0%, #ffffff 100%);
  position: absolute;
  border-top-left-radius: 13px;
  border-top-right-radius: 13px;
  background-position: top center;
  height: 100%;
  width: 100%;
  z-index: 1;
}
.card_title {
  font-family: "quicksand_medium", Sans-serif;
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--green-color-dark);
  letter-spacing: 4.3px;
}
.border_bottom {
  border-bottom: 1px solid var(--gold-color);
}
.profile_img {
  height: 50px;
  width: 50px;
  border-radius: 50%;
  position: relative;
  border: 2px solid #fff;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 5px 5px 35px 0px rgb(0 0 0 / 17%);
}
.profile_img img {
  height: 100%;
  width: 100%;
  border-radius: 50%;
}

.item_img {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  object-fit: cover;
  background-color: rgba(0, 0, 0, 0.1);
}
.item {
  color: #4E5356;
  font-size: 15px;
}
.delete {
  color: #E0E0E0;
  cursor: pointer;
}
.delete:hover {
  color: var(--red-color);
}
.borders {
  border-bottom: 1px solid #E0E0E0;
}
.borders:hover {
  background-color: rgb(244, 244, 244);
}
.hide {
  visibility: hidden;
}
.background-grey {
  background-color: rgb(244, 244, 244);
}
.item_nav {
  background-color: rgba(0, 0, 0, 0.53);
}
.hover:hover {
  color: var(--gold-color);
}
.disabled {
  opacity: 0.7;
}
</style>
